import React from "react";

export * as useMiddleware from "./useMiddleware";
export * as localHelpers from "./helpers";

export const isOnboardingComplete = (user) => {
  let isOnboardingComplete = true;
  const onboardingNeeds = [
    "name",
    "website_url",
    "logo",
    "color_code",
    "text_color",
    "description",
  ];
  onboardingNeeds.forEach((key) => {
    if (user === undefined || user === null) isOnboardingComplete = false;
    if (user) {
      if (user?.[key] === undefined || user?.[key] === null)
        isOnboardingComplete = false;
    }
  });

  return isOnboardingComplete;
};

export const hasCompletedOnboarding = (user) =>
  user?.fully_setup || isOnboardingComplete(user);

export const isNotEmpty = (value) =>
  value !== null && value !== undefined && value !== "";

/**
 * Verifies the object is a React element. Returns true or false.
 * https://reactjs.org/docs/react-api.html#isvalidelement
 */
export const isValidElement = (object) => React.isValidElement(object);

export const isFunctionComponent = (component) =>
  String(component)?.includes("return React.createElement");

export const isReactComponent = (component) =>
  (typeof component === "function" && !!component.prototype.isReactComponent) ||
  isFunctionComponent(component);

export const mapObjectValues = (obj) =>
  Object.values(obj).map((item, key) => item);

export const getQueryParam = (query) =>
  new URLSearchParams(window?.location?.search).get(query);

export const getQueryParams = (url = window.location) => {
  // Create a params object
  let params = {};

  new URL(url).searchParams.forEach(function (val, key) {
    if (params[key] !== undefined) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key]];
      }
      params[key].push(val);
    } else {
      params[key] = val;
    }
  });

  return params;
};

export const handleDownload = async (filename = "", url = "") => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error(error.message));
};
