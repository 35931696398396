import React from "react";
import styled from "styled-components";

const SideInfoLayout = ({ children }) => (
  <Wrapper>
    <div className="h-100 overflow-auto">{children}</div>
  </Wrapper>
);

/**
 * styles
 */
export const CardItem = styled.div`
  padding: 24px;
  cursor: pointer;
  line-height: 24px;
  position: relative;
  border-radius: 10px;
  border: solid 1px var(--grey-3);

  .card__icon {
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 16px;
    display: flex;
    height: 40px;
    width: 40px;

    svg {
      height: 40px;
    }

    &.-gradient {
      background: var(--adp-grad-2);
    }
  }

  p {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .card__check {
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    position: absolute;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: var(--green);

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .card__header {
    font-size: 18px;
    font-weight: 500;
  }

  .card__desc {
    color: var(--grey-2);
  }

  @media (min-width: 992px) {
    max-width: 100%;
  }
`;

export const Content = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;

  @media (min-width: 992px) {
    padding-top: 64px;
    padding-bottom: 64px;
    margin: 0 auto;
    max-width: ${({ width }) => `${width}px`};
  }
`;

const Wrapper = styled.div`
  height: calc(100% - 70px);
  position: relative;
  margin: 0 auto;

  .container {
    overflow-y: auto;
    height: 100px;
  }
`;

export default SideInfoLayout;
