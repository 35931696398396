import React, { cloneElement } from "react";
import styled from "styled-components";

const SessionLayout = ({ children, ...props }) => {
  const child = <SessionStyled {...props}>{children}</SessionStyled>;

  return cloneElement(child, { ...props });
};

const SessionStyled = styled.div`
  display: grid;
  padding-bottom: 5rem;

  .x-padding {
    @media (min-width: 991px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    flex-direction: column;
  }
`;

export default SessionLayout;
