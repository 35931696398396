import styled from "styled-components";

/**
 * style
 */

export const Divider = styled.hr`
  border-color: ${({ color }) => color || "var(--grey-3)"};
  width: calc(100% - 32px);
  margin: 12px auto;
  opacity: 0.5;
`;
