import React, { cloneElement } from "react";
import styled from "styled-components";

const GuestLayout = ({ children, ...props }) => {
  const child = <GuestStyled {...props}>{children}</GuestStyled>;

  return cloneElement(child, props);
};

const GuestStyled = styled.div`
  height: 100%;
  display: flex;
  overflow-y: hidden;

  img {
    height: 100%;
  }

  > :last-child {
    margin-top: 20vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    min-width: 21.875rem; // 350px

    .title {
      max-width: 290px; // 18.125rem
      text-align: center;
      margin: 0 auto;
    }
  }
`;

export default GuestLayout;
