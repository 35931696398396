import React, { Fragment } from "react";
import { useTitle } from "@adplist/adplist-react-lib";
import styled from "styled-components";

import Header from "Components/Headers/Header";

const OnboardingLayout = ({ children, ...props }) => {
  /**
   * hook
   */
  useTitle("Welcome");

  return (
    <Fragment>
      <div className="m-4 px-5">
        <Header logo="/favicon-32x32.png" slug="welcome" />
      </div>
      <OnboardingStyled {...props}>{children}</OnboardingStyled>;
    </Fragment>
  );
};

const OnboardingStyled = styled.div`
  display: flex;
  padding-top: 10vh;
  padding-bottom: 10vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .grid {
    display: grid;
    grid-gap: 16px;
  }

  .title {
    max-width: 430px; // 26.875rem
    text-align: center;
    margin: 0 auto;
  }
`;

export default OnboardingLayout;
