import React, { useGlobal } from "reactn";
import { Icon } from "@adplist/adplist-react-lib";

import { namedRoutes } from "Routes";
import Layout from "../Components/Layout/index";
import Sessions from "./Session/Index";

const Session = () => {
  /**
   * state
   */
  const [user, setUser] = useGlobal("user");

  return (
    <Layout.Session>
      <div className="main mt-20">
        <div className="container-fluid px-60">
          <div className="header__logo pb-1 mb-32 d-flex justify-content-end">
            <a
              href={namedRoutes.dashboard.sessions.index.replace(":tab?", "")}
              aria-label="Group Session"
            >
              <Icon.CloseCircle
                size={48}
                fill="var(--grey-5)"
                className="banner__close"
              />
            </a>
          </div>
        </div>

        <div className="container">
          <div className="x-padding">
            <Sessions
              {...{
                profile: user[process.env.REACT_APP_MENTOR] || user,
                user,
                setUser,
              }}
            />
          </div>
        </div>
      </div>
    </Layout.Session>
  );
};

export default Session;
