import { rootState } from "./";
import { cookie } from "@adplist/adplist-react-lib";

export const login = (state, dispatch, { token, ...payload }) => {
  const globalToken = cookie().getCookie("token");
  if (globalToken) {
    if (globalToken !== token?.actual) {
      cookie().deleteCookie("token");
    }
  }

  if (token) {
    cookie().setCookie("token", token.actual);
  }

  return {
    user: { ...payload },
    token: token.actual,
  };
};

export const logout = (state, dispatch, deleteToken = true) => {
  if (deleteToken) {
    cookie().deleteCookie("token");
  }

  // window.analytics.reset();
  return rootState;
};
