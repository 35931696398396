import React, { useState, useGlobal } from "reactn";
import { Notify, Type } from "@adplist/adplist-react-lib";
import { toast } from "react-toastify";
import styled from "styled-components";
import dayjs from "dayjs";

import { createGroupSessionService } from "Services/groupSessionService";
import { namedRoutes } from "Routes";
import { Content } from "Components/SideInfoLayout";
import DetailsSection from "./Details/DetailsSection";
import AboutSection from "./Details/AboutSection";
import SuccessModal from "./Details/SuccessModal";

const Details = ({ history }) => {
  /**
   * state
   */
  const [modal, setModal] = useState(false);
  const [session, setSession] = useState();
  const [section, setSection] = useState("about");
  const [form = {}, setForm] = useGlobal("groupSession");

  /**
   * function
   */
  const handleSubmit = ({ date, time, ...params }, { setSubmitting }) => {
    if (!params.video_url) {
      params.use_adplist_meet = true;
    }

    params.date_and_time = dayjs(date).tz().hour(dayjs(time).hour()).minute(dayjs(time).minute()).format(); // prettier-ignore

    const formData = new FormData();
    Object.keys(params).map((key) => formData.append(key, params[key]));
    console.log(params);
    return createGroupSessionService(formData)
      .then((response) => setSession(response) | setModal(true))
      .catch(() =>
        toast(<Notify body="Unable to create group session" type="error" />),
      )
      .finally(() => setSubmitting(false));
  };

  return (
    <Content width={650}>
      <div style={{ maxWidth: 300 }} className="mb-3">
        <Type.T3 className="mb-32">
          Let’s publish this session to the community.
        </Type.T3>

        <Ticks>
          <span className={section === "about" ? "active" : ""} />
          <span className={section === "details" ? "active" : ""} />
        </Ticks>
      </div>

      {section === "about" && (
        <AboutSection {...{ setSection, form, setForm }} />
      )}

      {section === "details" && (
        <DetailsSection {...{ setSection, form, setForm, handleSubmit }} />
      )}

      <SuccessModal
        show={modal}
        details={session}
        onHide={() =>
          history.push(
            namedRoutes.dashboard.sessions.details
              .replace(":tab", "about")
              .replace(":slug", session?.slug),
          )
        }
      />
    </Content>
  );
};

/**
 * styles
 */
const Ticks = styled.div`
  display: flex;

  span {
    width: 24px;
    height: 4px;
    border-radius: 30px;
    margin-right: 0.25rem;
    background-color: var(--grey-3);

    &.active {
      background-color: var(--teal);
    }
  }
`;

export default Details;
