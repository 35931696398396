import React, { Fragment } from "react";
import { GroupSession, Icon } from "@adplist/adplist-react-lib";
import styled from "styled-components";

import { CardItem } from "Components/SideInfoLayout";

const Video = ({ values, setFieldValue }) => {
  /**
   * variables
   */
  const options = [
    // {
    //   icon: Icon.AdpLogo,
    //   slug: "dyte",
    //   name: "ADPList Meet",
    //   desc: "Uses ADPList internal video calling feature",
    // },
    {
      icon: Icon.Video,
      slug: "others",
      name: "Video Link",
      desc: "Zoom/Google Meet/other video meeting url",
    },
  ];

  const { video_type, video_url } = values;

  return (
    <Fragment>
      <Wrapper>
        {options.map((option, key) => (
          <CardItem
            key={key}
            className="py-32"
            onClick={() => {
              if (option.slug === "dyte") setFieldValue("video_url", "");
              setFieldValue("video_type", option.slug);
            }}
          >
            {video_type === option?.slug && (
              <div className="card__check">
                <Icon.Check color="#fff" />
              </div>
            )}
            <div
              className={`card__icon ${
                option?.slug === "dyte" ? "-gradient" : "muted-blue-bg"
              }`}
            >
              <option.icon
                size={32}
                variant="icon"
                {...(option?.slug === "dyte" && { color: "transparent" })}
              />
            </div>
            <p className="card__header">{option.name}</p>
            <p className="card__desc">{option.desc}</p>
          </CardItem>
        ))}
      </Wrapper>

      {video_type === "others" && (
        <GroupSession.Fields.MeetingURL
          label="Link to meeting"
          value={video_url}
        />
      )}
    </Fragment>
  );
};

/**
 * style
 */
const Wrapper = styled.div`
  gap: 20px;
  display: grid;
  overflow-x: auto;
  margin-bottom: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
`;

export default Video;
