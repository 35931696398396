import React, { useEffect, useGlobal } from "reactn";
import { Switch, Route, useParams } from "react-router-dom";
import { Type, Icon, Grid } from "@adplist/adplist-react-lib";

import SideInfoLayout, { Content } from "Components/SideInfoLayout";
import { namedRoutes } from "Routes";
import Details from "./Create/Details";
import Start from "./Create/Start";

const Create = ({ history }) => {
  /**
   * variables
   */
  const { step } = useParams();
  const createUrl = namedRoutes.sessions.create;
  const sideInfo = [
    {
      icon: Icon.Users,
      title: "Scale your mentorship",
      desc: "With over 10k members, you can build your group of mentees here for free.",
    },
    {
      icon: Icon.TickSquare,
      title: "Easy to get started",
      desc: "Don’t worry about invites or comms. Create a session and we’ll help with the rest.",
    },
    {
      icon: Icon.Chat,
      title: "Freedom to host",
      desc: "Workshops, mini office hours, and more - a myraid of creative ways to host a session. ",
    },
  ];

  const steps = [
    {
      path: createUrl.replace(":step", "start"),
      component: Start,
    },
    {
      path: createUrl.replace(":step", "details"),
      component: Details,
    },
  ];

  /**
   * state
   */
  const [, setForm] = useGlobal("groupSession");

  /**
   * effect
   */
  useEffect(() => {
    // component unmount
    return () => setForm({});
  }, [setForm]);

  return (
    <SideInfoLayout
      children={
        <Switch>
          {steps.map((step, key) => (
            <Route exact key={key} {...step} />
          ))}
        </Switch>
      }
      {...(step === "start" && {
        sideInfo: (
          <Content width={316} className="mx-auto">
            <Type.T3 className="mb-4">
              Welcome to a new way to mentor at scale
            </Type.T3>

            <Grid gap="24px">
              {sideInfo.map((info, key) => (
                <div key={key}>
                  <info.icon size={44} className="mb-4" />
                  <Type.Large className="mb-2 font-weight-700">
                    {info.title}
                  </Type.Large>
                  <p className="grey-1-text">{info.desc}</p>
                </div>
              ))}
            </Grid>
          </Content>
        ),
      })}
    />
  );
};

export default Create;
