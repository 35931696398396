import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { namedRoutes } from "Routes";
import Create from "./Mentor/Create";

const Home = () => {
  /**
   * variables
   */
  const { index, create, details } = namedRoutes.sessions;
  const { index: indexDashboard, details: detailsDashboard } =
    namedRoutes.dashboard.sessions;

  return (
    <Switch>
      <Route path={create} component={Create} />

      <Redirect from={index} to={indexDashboard} />
      <Redirect from={details} to={detailsDashboard} />
      <Redirect to={indexDashboard} />
    </Switch>
  );
};

export default Home;
