import React, { Fragment, useState } from "react";
import { Flag, Http, Field, Select, Spinner, Textarea } from "@adplist/adplist-react-lib"; // prettier-ignore
import { capitalize } from "lodash";
import { Form } from "react-bootstrap";
import debounce from "lodash/debounce";

export const Email = ({ email, label, ...props }) => (
  <Field
    type="email"
    name="email"
    value={email || ""}
    placeholder="some@emil.com"
    label={label || "Email Address"}
    {...props}
  />
);

export const Name = ({ value, label, ...props }) => (
  <Field
    name="name"
    value={value || ""}
    label={label || "Your full name"}
    placeholder="First and last name"
    {...props}
  />
);

export const Bio = ({ value, ...props }) => (
  <Field
    name="bio"
    value={value || ""}
    minRows={6}
    className="py-3 black-text"
    useComponent={false}
    component={Textarea}
    placeholder="Tell us more about yourself, your goals and what you love!"
    {...props}
  />
);

export const Location = ({
  label,
  value,
  countries,
  setFieldValue,
  ...props
}) => (
  <Field
    name="country_iso"
    component={Select}
    value={value || ""}
    label={label || "Which country do you live in?"}
    placeholder="Eg. USA, India, etc"
    onChange={({ value }) => setFieldValue("country_iso", value)}
    options={
      countries?.map((e) => ({
        label: `${Flag({ code: e.iso, emoji: true }) || ""}  ${e.name}`,
        value: e.iso,
      })) || []
    }
    {...props}
  />
);

export const Expertise = ({
  setFieldValue,
  expertises,
  value,
  label,
  ...props
}) => (
  <Field
    isMulti
    name="expertise"
    component={Select}
    value={value || ""}
    placeholder="Select relevant expertise"
    label={label || "Which expertise would you like to mentor?"}
    options={
      expertises?.map((e) => ({
        label: e.description,
        value: e.id,
      })) || []
    }
    onChange={(options) => setFieldValue("expertise", limitOptions(options))}
    {...props}
  />
);

export const LevelOfExperience = ({
  label,
  value,
  levels,
  setFieldValue,
  ...props
}) => (
  <Field
    name="level"
    component={Select}
    value={value || ""}
    placeholder="Select level"
    label={label || "Level of experience"}
    options={
      levels?.map((e) => ({
        label: e.level,
        value: e.id,
      })) || []
    }
    onChange={({ value }) => setFieldValue("level", value)}
    {...props}
  />
);

export const Employer = ({ value, label, ...props }) => (
  <Field
    name="employer"
    value={value || ""}
    placeholder="Eg. Apple, UCLA, etc."
    label={label || "Company/School"}
    {...props}
  />
);

export const Title = ({ value, label, ...props }) => (
  <Field
    name="title"
    value={value || ""}
    placeholder="Eg. Product Designer, Student etc. "
    label={label || "Your title"}
    {...props}
  />
);

export const PortfolioUrl = ({ value, label, ...props }) => (
  <Field
    type="url"
    value={value || ""}
    name="linkedin_url"
    placeholder="username"
    label={label || "LinkedIn"}
    {...props}
  />
);

export const Languages = ({
  label,
  value,
  langs = [],
  setFieldValue,
  ...props
}) => (
  <Field
    isMulti
    name="languages"
    component={Select}
    value={value || ""}
    placeholder="Eg. English, Spanish, etc"
    label={label || "What language(s) do you speak?"}
    options={
      langs?.map((l) => ({
        label: l.description,
        value: l.id,
      })) || []
    }
    onChange={(options) =>
      setFieldValue(
        "languages",
        options ? options.map(({ value }) => value) : [],
      )
    }
    {...props}
  />
);

export const InterestedInRemote = ({
  label,
  value,
  setFieldValue,
  ...props
}) => (
  <Field
    component={Select}
    value={value || ""}
    placeholder="Yes or No"
    name="interested_in_remote"
    label={label || "Are you interested in remote jobs?"}
    onChange={({ value }) => setFieldValue("interested_in_remote", value)}
    options={["yes", "no"].map((ar) => ({ label: capitalize(ar), value: ar }))}
    {...props}
  />
);

export const LookingFor = ({ value, setFieldValue, label, ...props }) => (
  <Field
    name="looking_for"
    component={Select}
    value={value || ""}
    placeholder="Full-time or Freelance etc"
    label={label || "What are you looking for?"}
    onChange={({ value }) => setFieldValue("looking_for", value)}
    options={["full-time", "freelance"].map((ar) => ({
      label: capitalize(ar),
      value: ar,
    }))}
    {...props}
  />
);

export const AffiliateCode = ({ value, setFieldValue }) => {
  /**
   * state
   */
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isValid, setValid] = useState(false);

  // verify code
  const handleCodeCheck = debounce((code) => {
    setLoading(true);
    Http.get(`/partner/${code}/`)
      .then(({ name, id }) => {
        if (setFieldValue) {
          setMessage(name);
          setFieldValue("partner_id", id);
        }
        return setValid(true);
      })
      .catch(() => setValid(false) | setMessage("This code is invalid"))
      .finally(() => setLoading(false));
  }, 2000);

  return (
    <Form.Group controlId="affiliate_code" className="item__form-group">
      <div className="input-container d-flex align-items-center">
        <Form.Control
          type="text"
          name="affiliate_code"
          placeholder="Please enter your code"
          onChange={({ currentTarget: { value } }) => handleCodeCheck(value)}
        />
        <CheckingCode {...{ loading }} />
      </div>

      {isValid && value && (
        <p className="teal-text font-size-12 mb-0">{message} code verified!</p>
      )}

      {!isValid && message && (
        <p className="danger-text font-size-12 mb-0">{message}</p>
      )}
    </Form.Group>
  );
};

const CheckingCode = ({ loading }) => (
  <Fragment>
    {loading && (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner size={20} color="var(--teal)" />
      </div>
    )}
  </Fragment>
);

export const Level = ({
  label,
  value,
  levels,
  setFieldValue,
  placeholder,
  ...props
}) => (
  <Field
    name="level"
    component={Select}
    value={value || ""}
    label={label || "Level of experience"}
    placeholder={placeholder || "Select level"}
    onChange={({ value }) => setFieldValue("level", value)}
    options={
      levels?.map((l) => ({
        label: l.level,
        value: l.id,
      })) || []
    }
    {...props}
  />
);

export const motivationsFilter = (motivations, motivation) => {
  if (motivations.includes(motivation))
    return motivations.filter((m) => !(m === motivation));
  return [...motivations, motivation];
};

const limitOptions = (options) => {
  if (!options) return [];
  if (options?.length > 3) options.shift();
  return options?.map((o) => o.value);
};
