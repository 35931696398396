import React from "reactn";
import { Icon, Modal, Button, helpers, copyToClipboard, Grid } from "@adplist/adplist-react-lib"; // prettier-ignore
import { Image } from "react-bootstrap";
import styled from "styled-components";

import giphy from "Assets/gifs/giphy.gif";
import Gif from "Assets/gifs/account-created.gif";

const SuccessModal = ({ onHide, show, details, ...props }) => {
  /**
   * varaiables
   */
  const { handleShare } = helpers;
  const url = process.env.REACT_APP_ADPLIST_URL + `/session/${details?.slug}`;
  const campaign = process.env.REACT_APP_UTM_EVENTS_PARAMETER;

  return (
    <Modal onHide={onHide} show={show} size="lg" centered>
      <Wrapper {...props}>
        <div className="modal__content">
          <div className="gif-wrapper">
            <div>
              <Image src={giphy} />
            </div>
            <div>
              <Image src={Gif} />
            </div>
          </div>
          <div className="modal__content__info">
            <h1>Your group session is now live!</h1>

            <p className="mb-24 font-size-16 grey-2-text description">
              Congrats on creating your latest group session. Share your
              session, get the community to attend and learn together!
            </p>

            <p className="mb-12 font-size-16 grey-1-text text-left font-weight-400 line-height-13">
              Share this session
            </p>

            <div className="share__url">
              <span>
                <a
                  href="/"
                  onClick={(e) => e.preventDefault()}
                  className="text-truncate"
                >
                  {url}
                </a>
              </span>
              <Icon.Copy
                className="mx-auto cursor-pointer"
                onClick={() => copyToClipboard(url)}
              />
            </div>

            <div className="share__buttons">
              <Grid md="repeat(2, 1fr)" gap="8px">
                <Button
                  isValid
                  onClick={() =>
                    handleShare("twitter", url, campaign, details?.name)
                  }
                  className="-linkedin btn-60 w-100"
                >
                  <Icon.Twitter color="white" size={18} />
                  <span>Tweet this session</span>
                </Button>
                <Button
                  isValid
                  className="-linkedin btn-60 w-100"
                  onClick={() =>
                    handleShare("linkedin", url, campaign, details?.name)
                  }
                >
                  <Icon.LinkedIn color="white" size={24} />
                  <span>Share on LinkedIn</span>
                </Button>
              </Grid>

              <Button
                isValid
                target="_blank"
                href={url || "#"}
                className="font-weight-600 black-border btn-60 w-100"
              >
                <span>Go to session page</span>
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </Modal>
  );
};

/**
 * styles
 */

const Wrapper = styled.div`
  .modal__content {
    display: grid;
    grid-template-columns: 1fr;

    .gif-wrapper {
      display: grid;
    }

    .gif-wrapper div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .gif-wrapper div:nth-child(1) {
      z-index: 1;
      grid-row: 1 / 4;
      grid-column: 1;

      img {
        width: 177px;
        height: 151px;
      }
    }

    .gif-wrapper div:nth-child(2) {
      grid-column: 1;
      grid-row: 3 / 5;

      img {
        width: 166px;
        height: 125px;
      }
    }

    @media (min-width: 768px) {
      grid-template-columns: 352px 1fr;

      .gif-wrapper {
        grid-template-columns: 1fr;
      }

      .gif-wrapper div:nth-child(1) {
        img {
          width: 352px;
          height: 300px;
        }
      }
      .gif-wrapper div:nth-child(2) {
        img {
          width: 330px;
          height: 248px;
        }
      }
    }

    &__info {
      padding: 30px 24px;
      text-align: center;

      h1 {
        font-size: 32px;
        font-weight: 600;
        line-height: 36px;
        margin-bottom: 21px;
        color: var(--default);
      }

      p {
        line-height: 24px;
      }

      @media (min-width: 768px) {
        padding-right: 40px;
        padding-left: 40px;
        text-align: left;

        h1 {
          max-width: 297px;
        }

        .description {
          max-width: 324px;
        }
      }
    }
  }

  .share__url {
    gap: 24px;
    width: 100%;
    height: 60px;
    display: grid;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 11px;
    border: solid 1px var(--grey-3);
    grid-template-columns: 1fr 54px;

    span {
      height: 100%;
      display: flex;
      overflow: hidden;
      padding-left: 18px;
      padding-right: 16px;
      border-right: solid 1px var(--black-20);

      a {
        width: 100%;
        margin: auto;
        opacity: 0.7;
        color: var(--grey-2);
      }
    }

    @media (min-width: 768px) {
      margin-bottom: 19px;
    }
  }

  .share {
    gap: 18px;
    display: grid;

    &__buttons {
      gap: 11px;
      width: 100%;
      display: grid;

      .btn {
        height: 56px;
        width: 100%;

        span {
          margin-left: 10px;
        }

        &.-linkedin {
          color: #fff;
          background-color: var(--black);
        }
      }
    }
  }
`;

export default SuccessModal;
