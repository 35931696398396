import React, { cloneElement } from "react";
import styled from "styled-components";

const DashboardLayout = ({ children, ...props }) => {
  const child = <DashboardStyled {...props}>{children}</DashboardStyled>;

  return cloneElement(child, { ...props });
};

const DashboardStyled = styled.div`
  display: grid;
  grid-template-columns: 290px 1fr;

  .x-padding {
    @media (min-width: 991px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    flex-direction: column;
  }
`;

export default DashboardLayout;
