import React, { useGlobal, useDispatch, useEffect } from "reactn";
import { ErrorBoundary, Http, cookie, AppProvider } from "@adplist/adplist-react-lib";
import { ToastContainer } from "react-toastify";

import Routes from "./Routes";

const App = () => {
  /**
   * states
   */
  const [user, setUser] = useGlobal("user");
  const [token, setToken] = useGlobal("token");

  /**
   * dispatcher
   */
  const logout = useDispatch("auth.logout");
  // const logout = () => console.log("logged out");

  /**
   * effect
   */
  useEffect(() => {
    if (token) {
      if (user) {
        Http.get(`/partner/${user?.affiliate_code}/`)
          .then((response) => setUser({ ...user, ...response }))
          .catch(
            () => logout() | cookie().deleteCookie("token") | setToken(null),
          );

        Http.get("/partner/insights/").catch(
          () => cookie().deleteCookie("token") | logout() | setToken(null),
        );
      } else return logout() | cookie().deleteCookie("token") | setToken(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, setUser, logout]);

  return (
    <ErrorBoundary>
      <AppProvider>
        <Routes />
        <ToastContainer hideProgressBar />
      </AppProvider>
    </ErrorBoundary>
  );
};

export default App;
