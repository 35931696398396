import React, { Fragment, useEffect } from "react";
import { Type, Button, schema, converter } from "@adplist/adplist-react-lib";
import { Formik } from "formik";
import { object } from "yup";
import { Form } from "react-bootstrap";

import { namedRoutes } from "Routes";
import AboutForm from "../../Forms/About";
import Back from "Components/Back";

const AboutSection = ({ setSection, setForm, form }) => {
  /**
   * effect
   */
  useEffect(() => {
    if (form?.cover_image) {
      try {
        URL.createObjectURL(form?.cover_image);
      } catch (error) {
        setForm({ ...form, cover_image: "" });
      }
    }
  }, [form, setForm]);

  return (
    <Fragment>
      <Type.Large className="mb-4 grey-1-text">
        What is this group session about?
      </Type.Large>

      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{
          banner: form?.banner || "",
          description: form?.description
            ? converter.makeHtml(form?.description)
            : "",
          name: form?.name || "",
        }}
        validationSchema={object({
          banner: schema.requireFile("Banner", ["image"], false),
          name: schema
            .requireString("Name")
            .max(50, "Name is limited to 50 characters"),
          description: schema.requireString("Description"),
        })}
        onSubmit={({ description, ...params }) =>
          setForm({
            ...form,
            ...params,
            description: converter.makeMd(description),
          }) | setSection("details")
        }
      >
        {({
          setFieldValue,
          isSubmitting,
          handleSubmit,
          isValid,
          values,
          errors,
        }) => (
          <Form>
            <AboutForm {...{ setFieldValue, values, errors }} />
            <div className="d-flex justify-content-end mt-5">
              <Back
                prev={namedRoutes.sessions.create.replace(":step", "start")}
              >
                <Button
                  default
                  className="px-32"
                  isValid={isValid}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Continue
                </Button>
              </Back>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default AboutSection;
