import React, { Fragment, useState, useDispatch } from "reactn";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Image from "react-bootstrap/Image";
import PropTypes from "prop-types";

import { Button, Icon } from "@adplist/adplist-react-lib";
import { namedRoutes } from "Routes";
import { Divider } from "Styles";

const propTypes = {
  logo: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
};

const Header = ({ logo, links, slug }) => {
  /**
   * state
   */
  const [toggle, setToggle] = useState(false);

  /**
   * variable
   */
  logo = typeof logo === "object" ? URL.createObjectURL(logo) : logo;

  /**
   * dispatch
   */
  const logout = useDispatch("auth.logout");

  return (
    <div className="header">
      <Dropdown
        show={toggle}
        expanded="false"
        onToggle={(e) => setToggle(e)}
        className="position-static"
      >
        <Dropdown.Toggle className="position-relative cursor-pointer" as="a">
          <div className="header__logo pb-1 mb-32 d-flex justify-content-end">
            <Image
              width={40}
              height={40}
              alt="Logo"
              src={logo || ""}
              className="img-fit rounded-circle"
            />
            {slug !== "welcome" && (
              <Button
                isValid
                height={48}
                style={{ width: 160 }}
                className="teal-bg ml-2 white-text btn-60"
                href={namedRoutes.sessions.create?.replace(":step", "start")}
              >
                <Icon.Add className="mr-1" color="white" size={16} />
                <span>Create session</span>
              </Button>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{ top: 0, maxWidth: 160 }}
          className="p-0"
          align="right"
        >
          <div className="p-4">
            <Fragment>
              {links && (
                <Fragment>
                  {links?.map((item, index) => {
                    return (
                      <NavLink
                        key={index}
                        to={item?.url || "#"}
                        className="d-flex text-decoration-none black-text rounded lh-14 mb-16"
                        onClick={() => setToggle(false)}
                      >
                        {item.text}
                      </NavLink>
                    );
                  })}

                  <Divider className="mx-0 w-100 my-16" />
                </Fragment>
              )}

              <NavLink
                to={"/logout"}
                onClick={() => logout() | window.location.reload()}
                className="danger-text d-flex text-decoration-none lh-14"
              >
                Sign out
              </NavLink>
            </Fragment>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

Header.propTypes = propTypes;

export default Header;
