import { helpers } from "@adplist/adplist-react-lib";
import dayjs from "dayjs";

export const getTimeZone = () => {
  const offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);

  return (
    "GMT " +
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
};

export const formatDate = (user, date, format = "YYYY-MM-DD") =>
  `${dayjs(date).tz().format(format)}${
    user ? ` ${helpers.timezoneAbbr(user)}` : ""
  }`;
