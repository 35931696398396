import React from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "@adplist/adplist-react-lib";

const Back = ({ prev, back, children, ...props }) => {
  const history = useHistory();

  return (
    <div className="d-flex align-items-center" {...props}>
      <a
        href="/"
        onClick={(e) =>
          e.preventDefault() |
          (back ? back() : prev ? history.push(prev) : history.goBack())
        }
        className="d-flex align-items-center font-weight-700 grey-1-text text-decoration-none mr-28"
      >
        <Icon.ChevronLeft size={18} />
        <span className="ml-2">Back</span>
      </a>
      {children}
    </div>
  );
};

export default Back;
