import React, { Fragment, useRef } from "react";
import { Button, GroupSession } from "@adplist/adplist-react-lib";
import { Image, Form } from "react-bootstrap";
import styled from "styled-components";

const About = ({ values, errors, banner: coverImage, setFieldValue }) => {
  /**
   * variables
   */
  const { name, description, banner } = values;
  let imageRef = useRef();

  /**
   * functions
   */
  const handleImage = (image) => {
    if (image) {
      try {
        return URL.createObjectURL(image);
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <Form.Group>
        <Banner>
          <Image
            src={
              handleImage(banner)
                ? URL.createObjectURL(banner)
                : coverImage || "/photos/banner-bg.png"
            }
          />
          <Button
            default
            height={38}
            onClick={() => imageRef?.current?.click()}
          >
            Upload a session banner
          </Button>
          <input
            type="file"
            ref={imageRef}
            accept="image/*"
            className="d-none"
            onChange={({ currentTarget: { files } }) =>
              setFieldValue("banner", files[0])
            }
          />
        </Banner>
        {values?.banner && errors?.banner && (
          <p className="red-text font-size-12 mt-2 mb-0">{errors?.banner}</p>
        )}
      </Form.Group>

      <div className="mb-4">
        <GroupSession.Fields.SessionName
          value={name}
          maxLength={50}
          containerProps={{ className: "mb-0" }}
        />
        {name && (
          <p className="mt-1 grey-2-text font-size-12 ml-20">
            {50 - name?.length} characters remaining
          </p>
        )}
      </div>
      <GroupSession.Fields.Description
        value={description}
        placeholder="Type description here..."
        label="Event description or agenda"
        {...{ setFieldValue }}
      />
    </Fragment>
  );
};

/**
 * styles
 */
/**
 * styles
 */
const Banner = styled.div`
  height: 200px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 16px;
    object-position: center;
  }

  button {
    left: 24px;
    bottom: 24px;
    position: absolute;
  }

  @media (min-width: 992px) {
    height: 305px;
  }
`;

export default About;
