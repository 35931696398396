import React, { Fragment } from "react";
import { Type, schema, Button } from "@adplist/adplist-react-lib";
import { Formik } from "formik";
import { object } from "yup";
import { Form } from "react-bootstrap";
import useSWR from "swr";

import DetailsForm from "../../Forms/Details";
import Back from "Components/Back";

const DetailsSection = ({ form, setForm, setSection, handleSubmit }) => {
  /**
   * apis
   */
  const { data: motivation } = useSWR("/motivation/");
  const { data: expertises } = useSWR("/expertise/");

  return (
    <Fragment>
      <Type.Large className="mb-4 grey-1-text">
        Session details and schedule
      </Type.Large>

      <Formik
        validateOnMount
        initialValues={{
          date: form?.date || "",
          time: form?.time || "",
          rsvp_limit: form?.rsvp_limit ? parseInt(form.rsvp_limit) : "",
          expertise: form?.expertise || [],
          motivations: form?.motivations || [],
        }}
        validationSchema={object({
          date: schema.requireString("Date"),
          time: schema.requireString("Time"),
          rsvp_limit: schema
            .requireNumber("Attendees limit")
            .max(
              process.env.REACT_APP_GROUP_SESSION_RSVP,
              `Max of ${process.env.REACT_APP_GROUP_SESSION_RSVP} allowed`,
            ),

          motivations: schema.requireArray("Motivation"),
          expertise: schema.requireArray("Expertise"),
        })}
        onSubmit={(params, actions) =>
          handleSubmit({ ...form, ...params }, actions)
        }
      >
        {({ setFieldValue, handleSubmit, isSubmitting, isValid, values }) => (
          <Form>
            <DetailsForm
              {...{ setFieldValue, values, motivation, expertises }}
            />
            <div className="d-flex justify-content-end">
              <Back back={() => setSection("about")}>
                <Button
                  default
                  className="px-32"
                  isValid={isValid}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Publish Session
                </Button>
              </Back>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default DetailsSection;
