import React, { Fragment } from "react";
import { Grid, GroupSession, Pill, Type } from "@adplist/adplist-react-lib";
import Skeleton from "react-loading-skeleton-2";
import { Form } from "react-bootstrap";

import { motivationsFilter } from "Components/ProfileFormFields";

const Details = ({ values, setFieldValue, motivation, expertises }) => {
  /**
   * variables
   */
  const { date, time, rsvp_limit, motivations, expertise } = values;

  return (
    <Fragment>
      <Grid gap="24px" md="minmax(0, 1fr) 200px" className="mb-3">
        <GroupSession.Fields.Date
          name="date"
          value={date}
          {...{ setFieldValue }}
        />
        <GroupSession.Fields.Time
          name="time"
          value={time}
          className="form-control"
          {...{ setFieldValue }}
        />
      </Grid>
      <Type.Small className="mb-4 grey-2-text">
        In your timezone. People will see times in their timezone.
      </Type.Small>
      <Grid lg="minmax(0, 1fr) 200px" className="mb-4">
        <GroupSession.Fields.RsvpLimit max={600} value={rsvp_limit} />
      </Grid>

      <Grid lg="minmax(0, 1fr) 200px" className="mb-4">
        {!expertises && <Skeleton height={60} />}

        {expertises && (
          <GroupSession.Fields.Expertise
            value={expertise}
            options={expertises}
            {...{ setFieldValue }}
          />
        )}
      </Grid>

      <Form.Group className="mb-5">
        <p className="mb-3">Select a session interest area</p>
        <div className="d-flex flex-wrap mx-n1">
          {motivation?.map(({ id, ...motivation }, key) => (
            <Pill
              key={key}
              className="mx-1 mb-12 text-nowrap"
              title={motivation.caption}
              onClick={() =>
                setFieldValue("motivations", motivationsFilter(motivations, id))
              }
              {...(motivations.includes(id) && {
                active: true,
              })}
            >
              {motivation.description}
            </Pill>
          ))}
        </div>
      </Form.Group>
    </Fragment>
  );
};

export default Details;
