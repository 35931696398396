import React, { useGlobal } from "reactn";
import { Type, Button, schema } from "@adplist/adplist-react-lib";
import { Form } from "react-bootstrap";
import { object, string } from "yup";
import { Formik } from "formik";

import { Content } from "Components/SideInfoLayout";
import { namedRoutes } from "Routes";
import VideoForm from "../Forms/Video";

const Start = ({ history }) => {
  /**
   * state
   */
  const [form = {}, setForm] = useGlobal("groupSession");

  /**
   * functions
   */
  const handleSubmit = ({ video_url }) => {
    setForm({ ...form, video_url });

    return history.push(
      namedRoutes.sessions.create.replace(":step", "details"),
    );
  };

  return (
    <Content width={650}>
      <div className="mb-32">
        <Type.T2 className="mb-12">
          Let’s get started mentoring in groups.
        </Type.T2>
        <p>Where would you like your group session to happen?</p>
      </div>

      <Formik
        validateOnMount
        initialValues={{
          video_type: "others" || form?.video_url ? "others" : "dyte",
          video_url: form?.video_url || "",
        }}
        validationSchema={object({
          video_type: schema.requireString("Video type"),
          video_url: string().when("video_type", (type, schema) =>
            type === "others"
              ? schema
                  .url("Enter a valid url")
                  .required("Video url is required")
              : schema,
          ),
        })}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, setFieldValue, isValid, isSubmitting }) => (
          <Form>
            <VideoForm {...{ values, setFieldValue }} />

            <div className="d-flex justify-content-end mt-5">
              <Button
                default
                type="submit"
                className="px-32"
                isValid={isValid}
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                Create session
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Content>
  );
};

export default Start;
