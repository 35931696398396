const { Http } = require("@adplist/adplist-react-lib");

export const createGroupSessionService = (payload) =>
  Http.post(`/group-session/`, payload);

export const getAGroupSessionService = ({ slug }) =>
  Http.get(`/group-session/${slug}/`);

export const updateGroupSessionService = (id, payload) =>
  Http.put(`/group-session/${id}/`, payload);

export const addCoHostToSessionService = (id, payload) =>
  Http.post(`/group-session/${id}/co-host/`, payload);

export const deleteCoHostInSessionService = (id, user_id) =>
  Http.delete(`/group-session/${id}/co-host/?user_id=${user_id}`);

export const cancelGroupSessionService = (id) =>
  Http.put(`/group-session/${id}/cancel/`);

export const declineCoHostRequestService = (id) =>
  Http.delete(`/group-session/${id}/co-host/decline/`);

export const inviteGuestToSessionService = (id, payload) =>
  Http.post(`/group-session/${id}/invite/`, payload);

export const downloadRsvpsGroupSessionService = (id) =>
  Http.get(`/group-session/${id}/export/`, { responseType: "blob" });

export const addWaitlistToRsvpService = (id, payload) =>
  Http.put(`/group-session/${id}/waitlist/add/`, payload);

export const sendEmailToAudience = (id, payload) =>
  Http.post(`/group-session/${id}/send-email/`, payload);
