import React, { useGlobal, lazy, Suspense } from "reactn"; // prettier-ignore
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom"; // prettier-ignore

import { isOnboardingComplete } from "Utils";
import ScrollToTop from "Components/ScrollToTop";
import Fallback from "Components/Fallback";
import Session from "Views/Session";

/**
 * views import
 */
const Onboarding = lazy(() => import("./Views/Onboarding"));
const Dashboard = lazy(() => import("./Views/Dashboard"));
const Auth = lazy(() => import("./Views/Auth"));

const Routes = () => {
  /**
   * state
   */
  const [user] = useGlobal("user");
  const [token] = useGlobal("token");

  /**
   * variable
   */
  const hasCompletedOnboarding =
    user?.fully_setup || isOnboardingComplete(user);

  return (
    <Router>
      <ScrollToTop />
      <Suspense
        fallback={<Fallback>Loading {user ? "Dashboard" : "App"}</Fallback>}
      >
        <Switch>
          {user && hasCompletedOnboarding && (
            <Route exact path="/dashboard/*" component={Dashboard} />
          )}

          {user && hasCompletedOnboarding && (
            <Route exact path="/session*" component={Session} />
          )}
          {user && !hasCompletedOnboarding && (
            <Route exact path="/welcome" component={Onboarding} />
          )}
          {!user && <Route exact path="/auth/*" component={Auth} />}

          {/* root path */}
          <Route
            exact
            path="/"
            render={(props) =>
              token && user ? (
                !hasCompletedOnboarding ? (
                  <Redirect to="/welcome" />
                ) : (
                  <Dashboard {...props} />
                )
              ) : (
                <Auth {...props} />
              )
            }
          />
          <Redirect from="/dashboard/*" to="/dashboard" />
          <Redirect from="/session/*" to="/session" />
          <Redirect from="/welcome/*" to="/welcome" />
          <Redirect from="/*" to="/" />
        </Switch>
      </Suspense>
    </Router>
  );
};

/**
 * namedRoutes
 *
 * Sometimes it is very import to name your routes if there's
 * a possibility of the routes changing often.
 *
 * This ensures that you have your routes defined at a central
 * place where you can update and have it reflect everywhere it
 * is used
 *
 * Usage
 *
 * import {namedRoutes} from "Routes";
 *
 * namedRoutes.home.index
 * namedRoutes.settings.profile
 * ....
 */
export const namedRoutes = {
  reset: {
    index: "/reset",
  },
  auth: {
    login: "auth/login",
    reset: "auth/reset",
    logout: "auth/logout",
    create: "auth/create",
    verify: "auth/verify",
  },
  onboarding: {
    index: "/welcome",
    welcome: {
      index: "/welcome/setup",
      step: "/welcome/setup/mentor/:tab/step",
      finish: "/welcome/setup/mentor/finish",
    },
  },

  dashboard: {
    index: "/dashboard",
    directory: {
      index: "/dashboard/directory/",
      tab: "/dashboard/directory/:tab?",
      mentors: "/dashboard/directory/mentors",
      mentees: "/dashboard/directory/mentees",
      groupSessions: "/dashboard/directory/group-session",
    },
    sessions: {
      index: "/dashboard/sessions/:tab?",
      create: "/dashboard/sessions/create/:step/step",
      details: "/dashboard/sessions/details/:slug/:tab",
    },
    settings: { index: "/dashboard/settings" },
    profile: { index: "/dashboard/profile" },
  },
  sessions: {
    index: "/session/:tab?",
    create: "/session/create/:step/step",
    details: "/session/details/:slug/:tab",
  },
};

/**
 * all supported apps
 *
 * these are all the apps that the auth app
 * will redirect to if appended in the query string
 * If app doesn't exist in app, no redirect will happen
 */
export const supportedApps = [
  {
    name: "Projects",
    slug: "project",
    url: process.env.REACT_APP_PROJECTS_URL,
  },
  {
    name: "Adplist",
    slug: "adplist",
    url: process.env.REACT_APP_ADPLIST_URL,
  },
];

export default Routes;
