import React from "react";
import BarLoader from "react-spinners/BarLoader";
import styled from "styled-components";
import { ReactComponent as Logo } from "../Assets/Icons/adplist.svg";

const Fallback = ({ children }) => (
  <Wrapper>
    <div className="text-center">
      <div className="my-5">
        <Logo alt="logo" />
      </div>
      <h5 className="mb-4 font-weight-bold">{children}</h5>
      <BarLoader
        height={5}
        width={280}
        css={{ display: "block" }}
        color="#131313"
      />
    </div>
  </Wrapper>
);

/**
 * styles
 */
const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
`;

export default Fallback;
